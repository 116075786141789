import validate from "/mcc/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.9_eslint@8.57.1_typescript@5.6.2_vite@5.4.8_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45global from "/mcc/src/middleware/redirect.global.ts";
import manifest_45route_45rule from "/mcc/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.9_eslint@8.57.1_typescript@5.6.2_vite@5.4.8_vue-tsc@1.8.27/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  admin: () => import("/mcc/src/middleware/admin.ts"),
  auth: () => import("/mcc/src/middleware/auth.ts"),
  committee: () => import("/mcc/src/middleware/committee.ts"),
  "public-only": () => import("/mcc/src/middleware/public-only.ts")
}