import revive_payload_client_yAbOzNWD51 from "/mcc/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.9_eslint@8.57.1_typescript@5.6.2_vite@5.4.8_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_5hcrxMJdzE from "/mcc/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.9_eslint@8.57.1_typescript@5.6.2_vite@5.4.8_vue-tsc@1.8.27/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ogROlggTzu from "/mcc/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.9_eslint@8.57.1_typescript@5.6.2_vite@5.4.8_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_69V4auuLN5 from "/mcc/node_modules/.pnpm/nuxt-site-config@2.2.18_vite@5.4.8_vue@3.5.9/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_Gp5bawc3fb from "/mcc/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.9_eslint@8.57.1_typescript@5.6.2_vite@5.4.8_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_LtfRcKfl6x from "/mcc/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.9_eslint@8.57.1_typescript@5.6.2_vite@5.4.8_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_nxhMTPGUDp from "/mcc/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.9_eslint@8.57.1_typescript@5.6.2_vite@5.4.8_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_GQkCJLSkaR from "/mcc/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.9_eslint@8.57.1_typescript@5.6.2_vite@5.4.8_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_OVNC7dQDL5 from "/mcc/node_modules/.pnpm/@pinia+nuxt@0.5.4_typescript@5.6.2_vue@3.5.9/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/mcc/.nuxt/components.plugin.mjs";
import prefetch_client_iwuVt4QdHo from "/mcc/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.9_eslint@8.57.1_typescript@5.6.2_vite@5.4.8_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_z1edI6N6Jr from "/mcc/node_modules/.pnpm/@nuxt+ui@2.18.6_vite@5.4.8_vue@3.5.9/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_W0Wm5iH9TC from "/mcc/node_modules/.pnpm/@nuxt+ui@2.18.6_vite@5.4.8_vue@3.5.9/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_gbd1Vj0UVS from "/mcc/node_modules/.pnpm/@nuxt+ui@2.18.6_vite@5.4.8_vue@3.5.9/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_1rze2VsG7c from "/mcc/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_cFc4dyIBeu from "/mcc/node_modules/.pnpm/@nuxt+icon@1.5.2_vite@5.4.8_vue@3.5.9/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_wy0B721ODc from "/mcc/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import floating_vue_EIcJ7xiw0h from "/mcc/.nuxt/floating-vue.mjs";
import script_hHEPzAINpx from "/mcc/node_modules/.pnpm/@nuxtjs+turnstile@0.6.3/node_modules/@nuxtjs/turnstile/dist/runtime/plugins/script.mjs";
import plugin_QUJKl7CH1E from "/mcc/node_modules/.pnpm/nuxt-directus@5.6.1/node_modules/nuxt-directus/dist/runtime/plugin.mjs";
import umami_iN1DWXMIUx from "/mcc/node_modules/.pnpm/nuxt-umami@2.6.7/node_modules/nuxt-umami/plugins/umami.ts";
import authRefresh_client_zjlpzuMCqF from "/mcc/src/plugins/authRefresh.client.ts";
import redirects_oxa5gmVmKq from "/mcc/src/plugins/redirects.ts";
import sentry_client_KAXFuL2wum from "/mcc/src/plugins/sentry.client.ts";
export default [
  revive_payload_client_yAbOzNWD51,
  unhead_5hcrxMJdzE,
  router_ogROlggTzu,
  _0_siteConfig_69V4auuLN5,
  payload_client_Gp5bawc3fb,
  navigation_repaint_client_LtfRcKfl6x,
  check_outdated_build_client_nxhMTPGUDp,
  chunk_reload_client_GQkCJLSkaR,
  plugin_vue3_OVNC7dQDL5,
  components_plugin_KR1HBZs4kY,
  prefetch_client_iwuVt4QdHo,
  slideovers_z1edI6N6Jr,
  modals_W0Wm5iH9TC,
  colors_gbd1Vj0UVS,
  plugin_client_1rze2VsG7c,
  plugin_cFc4dyIBeu,
  plugin_wy0B721ODc,
  floating_vue_EIcJ7xiw0h,
  script_hHEPzAINpx,
  plugin_QUJKl7CH1E,
  umami_iN1DWXMIUx,
  authRefresh_client_zjlpzuMCqF,
  redirects_oxa5gmVmKq,
  sentry_client_KAXFuL2wum
]